/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

.add-button {
  position: absolute;
  top: 1px;
  left: 1px;
}


/** Ionic CSS Variables **/
:root {
  /** primary **/

  --ion-color-primary: #005494;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

/*@media (prefers-color-scheme: light) {*/
/*  !**/
/*   * Dark Colors*/
/*   * -------------------------------------------*/
/*   *!*/

/*  body {*/
/*    --background: #005494;*/
/*    --ion-color-primary: #ffffff;*/
/*    --ion-color-primary-rgb: 66,140,255;*/
/*    --ion-color-primary-contrast: #ffffff;*/
/*    --ion-color-primary-contrast-rgb: 255,255,255;*/
/*    --ion-color-primary-shade: #3a7be0;*/
/*    --ion-color-primary-tint: #5598ff;*/

/*    --ion-color-secondary: #50c8ff;*/
/*    --ion-color-secondary-rgb: 80,200,255;*/
/*    --ion-color-secondary-contrast: #ffffff;*/
/*    --ion-color-secondary-contrast-rgb: 255,255,255;*/
/*    --ion-color-secondary-shade: #46b0e0;*/
/*    --ion-color-secondary-tint: #62ceff;*/

/*    --ion-color-tertiary: #6a64ff;*/
/*    --ion-color-tertiary-rgb: 106,100,255;*/
/*    --ion-color-tertiary-contrast: #ffffff;*/
/*    --ion-color-tertiary-contrast-rgb: 255,255,255;*/
/*    --ion-color-tertiary-shade: #5d58e0;*/
/*    --ion-color-tertiary-tint: #7974ff;*/

/*    --ion-color-success: #2fdf75;*/
/*    --ion-color-success-rgb: 47,223,117;*/
/*    --ion-color-success-contrast: #000000;*/
/*    --ion-color-success-contrast-rgb: 0,0,0;*/
/*    --ion-color-success-shade: #29c467;*/
/*    --ion-color-success-tint: #44e283;*/

/*    --ion-color-warning: #ffd534;*/
/*    --ion-color-warning-rgb: 255,213,52;*/
/*    --ion-color-warning-contrast: #000000;*/
/*    --ion-color-warning-contrast-rgb: 0,0,0;*/
/*    --ion-color-warning-shade: #e0bb2e;*/
/*    --ion-color-warning-tint: #ffd948;*/

/*    --ion-color-danger: #ff4961;*/
/*    --ion-color-danger-rgb: 255,73,97;*/
/*    --ion-color-danger-contrast: #ffffff;*/
/*    --ion-color-danger-contrast-rgb: 255,255,255;*/
/*    --ion-color-danger-shade: #e04055;*/
/*    --ion-color-danger-tint: #ff5b71;*/

/*    --ion-color-dark: #f4f5f8;*/
/*    --ion-color-dark-rgb: 244,245,248;*/
/*    --ion-color-dark-contrast: #000000;*/
/*    --ion-color-dark-contrast-rgb: 0,0,0;*/
/*    --ion-color-dark-shade: #d7d8da;*/
/*    --ion-color-dark-tint: #f5f6f9;*/

/*    --ion-color-medium: #989aa2;*/
/*    --ion-color-medium-rgb: 152,154,162;*/
/*    --ion-color-medium-contrast: #000000;*/
/*    --ion-color-medium-contrast-rgb: 0,0,0;*/
/*    --ion-color-medium-shade: #86888f;*/
/*    --ion-color-medium-tint: #a2a4ab;*/

/*    --ion-color-light: #222428;*/
/*    --ion-color-light-rgb: 34,36,40;*/
/*    --ion-color-light-contrast: #ffffff;*/
/*    --ion-color-light-contrast-rgb: 255,255,255;*/
/*    --ion-color-light-shade: #1e2023;*/
/*    --ion-color-light-tint: #383a3e;*/
/*    --color-checked: none !important;*/
/*  }*/

/*  !**/
/*   * iOS Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .ios body {*/
/*    --background: #005494;*/
/*    --ion-background-color: #000000;*/
/*    --ion-background-color-rgb: 0,0,0;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*    --ion-color-step-50: #0d0d0d;*/
/*    --ion-color-step-100: #1a1a1a;*/
/*    --ion-color-step-150: #262626;*/
/*    --ion-color-step-200: #333333;*/
/*    --ion-color-step-250: #404040;*/
/*    --ion-color-step-300: #4d4d4d;*/
/*    --ion-color-step-350: #595959;*/
/*    --ion-color-step-400: #666666;*/
/*    --ion-color-step-450: #737373;*/
/*    --ion-color-step-500: #808080;*/
/*    --ion-color-step-550: #8c8c8c;*/
/*    --ion-color-step-600: #999999;*/
/*    --ion-color-step-650: #a6a6a6;*/
/*    --ion-color-step-700: #b3b3b3;*/
/*    --ion-color-step-750: #bfbfbf;*/
/*    --ion-color-step-800: #cccccc;*/
/*    --ion-color-step-850: #d9d9d9;*/
/*    --ion-color-step-900: #e6e6e6;*/
/*    --ion-color-step-950: #f2f2f2;*/

/*    --ion-toolbar-background: #0d0d0d;*/

/*    --ion-item-background: #000000;*/
/*  }*/


/*  !**/
/*   * Material Design Dark Theme*/
/*   * -------------------------------------------*/
/*   *!*/

/*  .md body {*/
/*    --background: #005494;*/
/*    --ion-background-color: #005494;*/
/*    --ion-background-color-rgb: 18,18,18;*/

/*    --ion-text-color: #ffffff;*/
/*    --ion-text-color-rgb: 255,255,255;*/

/*    --ion-border-color: #222222;*/

/*    --ion-color-step-50: #1e1e1e;*/
/*    --ion-color-step-100: #2a2a2a;*/
/*    --ion-color-step-150: #363636;*/
/*    --ion-color-step-200: #414141;*/
/*    --ion-color-step-250: #4d4d4d;*/
/*    --ion-color-step-300: #595959;*/
/*    --ion-color-step-350: #656565;*/
/*    --ion-color-step-400: #717171;*/
/*    --ion-color-step-450: #7d7d7d;*/
/*    --ion-color-step-500: #898989;*/
/*    --ion-color-step-550: #949494;*/
/*    --ion-color-step-600: #a0a0a0;*/
/*    --ion-color-step-650: #acacac;*/
/*    --ion-color-step-700: #b8b8b8;*/
/*    --ion-color-step-750: #c4c4c4;*/
/*    --ion-color-step-800: #d0d0d0;*/
/*    --ion-color-step-850: #dbdbdb;*/
/*    --ion-color-step-900: #e7e7e7;*/
/*    --ion-color-step-950: #f3f3f3;*/

/*    --ion-item-background: #1e1e1e;*/

/*    --ion-toolbar-background: #1f1f1f;*/

/*    --ion-tab-bar-background: #005494;*/

/*  }*/
/*}*/

.app {
  --background: #005494;
  /*--background: linear-gradient(0deg, #FFFFFF 0%, #005494 100%);*/
  flex:1;
  justify-content: center;
}

.appBtn {
  --background: #005494;
  /*--border-radius: 0;*/
}

.footerBtnContainer {
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footerBtn {
  --box-shadow:none;
}

.footerText {
  color: #ffffff;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size:10px;
}

.contentTextTitle {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  margin-right: 7px;
}


.profileContentTextTitle {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  margin-right: 7px;
}

.aboutProfile {
    margin: 10px 10px 0px 10px;
}

.contentTextSubTitle {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.contentTextNormal {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
}

.contentText {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  margin-right: 5px;
}


.contentTextSubtitle {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  margin-right: 5px;
}


.contentTextItalic {
  color: #ffffff;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: italic;
  font-weight: 600;
}

.directionText {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
    color: #ffffff;
}
.appTextMain {
  color: #ffffff;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  //font-style: semi;
  font-weight: 700;
}

.appText{
  color: #ffffff;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: normal;
  font-weight: 600;
}

.taglineText {
  color: #ffffff;
  opacity:1;
  font-family: open-sans;
  font-style: italic;
  font-size: 18px;
  font-weight: 800;
}
.appTextMainItalic {
  color: #ffffff;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: italic;
  font-weight: 600;
}

.appLabelText {
  color: #ffffff;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-weight: 700;
  font-size:20pt;
}

.appTitle {
  color: #005494;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: italic;
  font-weight: 700;
  font-size: 20pt;
}

.btnTitle {
  font-family: freight-sans-compressed-pro, sans-serif;
  font-style: italic;
  font-weight: 900;
  font-size: 1.5em;
}
.editProfileButton {
    transform: scaleY(1.2);
    margin: 10px 0px 14px 0px;
    --background: none;
}
.editProfileButtonText {
    font-family: sans-serif;
    font-style: italic;
    font-weight: 500;
    font-size: 1.2em;
    padding: 5px 10px 5px 5px;
    border-radius: 6px;
    --color: #005494;
    color: #005494;
}
.editProfileButtonText:hover {
    background-color: lavender;
}


.chooseButtonText {
  font-family: sans-serif;
  font-style: italic;
  font-weight: 500;
  font-size: 1em;
  padding: 5px 10px 5px 5px;
  border-radius: 6px;
  --color: #005494;
  color: #005494;
}
.chooseButtonText:hover {
  background-color: lavender;
}

.changeButtonText {
  font-family: sans-serif;
  font-weight: 500;
  font-size: 1.2em;
  padding: 5px 10px 5px 5px;
  border-radius: 6px;
}

.changeButtonText:hover {
  background-color: lavender;
}

.uploadLaterButtonText {
  font-family: sans-serif;
  font-weight: 500;
  font-style: italic;
  font-size: 1.2em;
  padding: 5px 10px 10px 5px;
  border-radius: 6px;
  color: #ffffff;
  --color: #ffffff;
  --box-shadow:none;
}

.uploadLaterButtonText:hover {
  background-color: #005494;
}

.appLogoText {
  color: #ffffff;
  opacity:1;
  font-family: 'GordW00-Regular';
  font-size: 2em ;
}

.menuButton {
  color: #ffffff;
  opacity:1;
}

.labelText {
  opacity:1;
  font-family: freight-sans-pro, sans-serif;
  font-style: normal;
  font-weight: 900;
  color: #005494;
}

.menuButton:hover
{
  opacity:0.5;
  transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  -webkit-transition: opacity .2s ease-out;
  -o-transition: opacity .2s ease-out;
}

.appContent {
  padding-top: 10%;
}

.footerIcon {
  height: 100%;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
  align-self: center;
}

.nextButton {
  color: #ffffff;
}


.swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  border: white solid 1px;
  background-color: grey;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background-color: #005494;
}

.swiper-button-next{
  margin-top: 20px;
  position: absolute;
  top: 50%;
  width: 45px;
  height: 45px;
  transform: translateY(-50%);
  fill: lightblue;
}

  .swiper-button-prev{
    position: absolute;
    top: 50%;
    left: -40px;
    width: 45px;
    height: 45px;
    transform: translateY(-50%);
    margin-top: 0px;
  }

ion-segment-button {
  height: 40px;
  min-height: 40px;
  /*--border-radius: 0;*/

  }

  ion-segment-button.segment-button-checked {

   /*border-bottom: 2px solid;*/

  }

 .appLogo {
  margin-left: 40%;
  margin-right: 45%;
 }

.stepScreen {

  padding-top: 10%;
  text-align: center;
  background-color: #005494;
}

.stepCard {
  background-color: #005494;
}

.appVideoCardDisplay {
  width: 30%;
  padding-bottom: 56.25%;
  vertical-align: middle;
  object-fit: fill; /*to add black content at top and bottom*/
  object-position: 0 -14px; /* to center our image*/
  background-color: #005494;
  background-size: cover;
}

.appVideoDisplay {
  width: 100vw;
  vertical-align: middle;
  object-fit: fill; /*to add black content at top and bottom*/
  object-position: 0 -14px; /* to center our image*/
  background-size: cover;
  padding-bottom: 56.25%;
}

.videoCard {
  width: 25%;
  object-fit: fill; /*to add black content at top and bottom*/
  background-color: #005494;
  padding-bottom: 20px;
}

.gallery {
  border: 2px solid #ccc;
  padding-bottom: 20px;
}
.gallery:hover {
  border: 2px solid #777;
}

.gallery video {
  width: 100%;
}

.desc {
  padding: 15px;
  text-align: center;
}

.actions {
  text-align:center;
  padding-bottom:15px;
  padding-top: 15px;
}
* {
  box-sizing: border-box;
}

.card-responsive {
  padding: 0 6px;
  float: left;
  width: 33%;
}

.delivery-module {
  padding: 0 6px;
  float: left;
  width: 100%;
}
@media only screen and (max-width: 700px) {
  .card-responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 500px) {
  .card-responsive {
    width: 100%;
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.videoActionButton {
  --background:none;
  align-content: center;
  border: none;
}


.uploadVideoBtn {
  display: none;
}

.video-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.video-wrapper img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-wrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}



.videoModule{
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
object-fit:cover;
}

.loginPage__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.my-custom-class {
  --width: 70%;
  --height: 70%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;

}

.backstory-class {
  --width: 70%;
  overflow-y: scroll;
}

.cta-modal {
  --width: 70%;
  --height: 50%;
}

textarea.alert-input.sc-ion-alert-ios {
  resize: vertical !important;
  height: 200px;
}
.profile-custom-class {
  --width: 60%;
  --height: 30%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;

}

.join-custom-class {
  --width: 80%;
  --height: 80%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
}

.my-custom-class .action-sheet-group {
  background: #e5e5e5;
}
.list-popup {
  --width: 50%;
  --height: 30%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
}

.alert-head.sc-ion-alert-ios{
  background:  grey !important;
}

.alert-message.sc-ion-alert-ios {
  color: #ffffff;
  padding-top: 10%;
}

.profileModal {
  --width: 70%;
  --height: 70%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;

}

.missionContainer {
  background-color: #ffffff;
  color: #005494;
  --background: #ffffff;
  /*padding: 5px;*/
  /*margin: 5px 5px 5px 5px;*/
  /*margin: 0px 0px 5px 0px;*/
  /*border-radius: 0 !important;*/
  /*--border-radius: 0 !important;*/
}

.deliveryModuleContainer {
  background-color: #005494;
  color: #ffffff;
  --background: #005494;
  margin: 5px 5px 5px 5px;
  /*margin: 0px 0px 5px 0px;*/
  /*border-radius: 0 !important;*/
  /*--border-radius: 0 !important;*/
}

.challengeContainer {
  background-color: #005494;
  color: #ffffff;
  --background: #005494;
  margin: 5px 5px 5px 5px;
  /*margin: 0px 0px 5px 0px;*/
  /*border-radius: 0 !important;*/
  /*--border-radius: 0 !important;*/
}

.missionText {
  text-align: left;
  background-color: #ffffff;
  color: #005494;
  white-space: nowrap;
}

.deliveryModuleText {
  text-align: left;
  background-color: #005494;
  color: #ffffff;
  white-space: nowrap;
}


.footnote {
  color: #ffffff;
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-weight: 700;
  font-style: italic;
  font-size:9pt;
}

.contentSubtitle {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-weight: 700;
  font-size:8pt;
}

.creditLabel {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-weight: 500;
  font-size:8pt;
}

.creditLabelName {
  opacity:1;
  font-family: freight-sans-compressed-pro, sans-serif;
  font-weight: 700;
  font-size:10pt;
}


.hire {
    --background: url(https://jfh-app-assets.s3.amazonaws.com/brand/Blue_White_Background.jpg) center/100% auto no-repeat;
}

.profileVideo-wrapper {
  /*//position: relative;*/
  /*padding-bottom: 56.25%;*/
  width: 100%;
  overflow: hidden;
  display: inline-block;
}

.profileVideo-wrapper video {
  object-fit: cover !important;
  width: 100%;
  height: 250px;
}

.checklist-btn {
  width: 60px;
  height: 60px;
  --background: #005494;
  --box-shadow:none !important;
}

.alliesNo {
  font-family: freight-sans-compressed-pro, sans-serif;
  font-weight: 700;
  font-size:16pt;
  color: #005494;
  --background: none;
  --background-activated: none;
  --color: none;
  /*border: 1px 1px 1px 1px;*/
}

.loginBtnLabel {
  font-family: freight-sans-compressed-pro, sans-serif;
  font-weight: 700;
}

.registerBtnLabel {
  font-weight: bolder;
  font-style: italic;
}

.italic {
  font-style: italic;
}

.bolder {
  font-weight: bolder;
}

.playBtn {
  position: absolute;
  bottom: 2.5%;
  right: 6px;
  background-color: #005494;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.profilePlayBtn {
  position: absolute;
  top: 2.5%;
  left: 6px;
  background-color: #005494;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.accountPlayBtn {
  position: absolute;
  bottom: 2.5%;
  right: 27px;
  background-color: #005494;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;

}


.playpause {
  background-repeat:no-repeat;
  width:25%;
  height:25%;
  position:absolute;
  left:0%;
  right:0%;
  top:0%;
  bottom:0%;
  margin:auto;
  background-size:contain;
  background-position: center;
}

.noShadowBtnBox {
  --box-shadow:none;
}


.progressBar {
  height:25px;
}



.universeBtn {
  z-index: 1;
  flex: 1;
  max-width: 193px;
  height: 32px;
  --box-shadow: -2px 2px 4px -2px dimgray;
  --background: #ffffff;
  --color: #005494;
  --border-style: solid;
  --border-width: 2px 0px 2px 2px;
  --border-color: #005494;
  margin-left: 64%;
}

.universeBtn:hover {
  --background: #f5f5ff;
}

.universeDetailsBtn {
  height: 35px;
  justify-content: space-around;
  align-content: center;
}

.checkbox-send {
  --border-radius: 15px;
  --border-color: #005494;
}

.checkbox-direction {

}

.checkbox{
  --border-radius: 0 !important;
  background: #005494 url("/assets/img/checklist_REDO_BOX.png") no-repeat 50% 50%;
  --background: #005494 url("/assets/img/checklist_REDO_BOX.png") no-repeat 50% 50%;
  border-color: #ffffff;
  --border-color: #ffffff;
}
.checkbox-checked{
  --border-radius: 0 !important;
  background: #005494 url("/assets/img/checklist_REDO_CHECKED.png") no-repeat 50% 50%;
  --background: #005494 url("/assets/img/checklist_REDO_CHECKED.png") no-repeat 50% 50%;
  border-color: #ffffff;
  --border-color: #ffffff;
  --border-color-checked: #ffffff;
}

mark {
  background-color: lightslategrey;
  color: #005494;
  font-weight: bolder;
}

.ugc-content {
  position: relative;
  /*padding-bottom: 56.25%;*/
}

.ugc-content video {
  top: 0;
  left: 0;
  width: 50%;
  height: 50%;
  object-fit:cover;
}

.content-overlay {
  position:absolute;
  top:0;
  left:0;
  z-index:1;
  width: 50%;
  height: 95%;
}

.brandText {
   color:#005494;
}

.sharePopover {
  z-index: 1;
  --width: 100%;
  --height: 100%;
  top:50%;
}


.bottom-drawer {
  position: absolute;
  right: 4px;
  left: 4px;
  bottom: -380px;
  height: 400px;
  border-radius: 30px;
}

.popover-footer {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  background-color: #F7F7F7;
  border-bottom: 1px solid #EBEBEB;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  overflow-y : scroll;
  height: 200px;
}

.dm-content {
  /*position: relative;*/
  /*padding-bottom: 56.25%;*/
}

.dm-content video {
  top: 0;
  left: 0;
  --width: 100%;
  --height: 50%;
  object-fit:contain;
}

.dm-card {
  /*--width: 100%;*/
}

.acceptBtn {
 --background: yellow;
 --color:#005494;
  font-size:12px;
}

.completeBtn {
  --background:#005494;
  --color:#ffffff;
  font-size: 10px;
}



.child4 {
  display: inline-block;
  margin: 10px 0 0 2%;
  flex-grow: 1;
  height: 120px;
  width: calc(100% * (1/4) - 10px - 1px);
}

.child2 {
  display: inline-block;
  margin: 10px 0 0 2%;
  flex-grow: 1;
  height: 250px;
  width: calc(100% * (1/2) - 10px - 1px);
}

.child3 {
  display: inline-block;
  margin: 10px 0 0 2%;
  flex-grow: 1;
  height: 150px;
  width: calc(100% * (1/3) - 10px - 1px);
}

.native-input::-webkit-input-placeholder {
  text-align: center;
}

.native-input::-moz-placeholder {
  text-align: center;
}

.native-input::-moz-placeholder {
  text-align: center;
}

.native-input:-ms-input-placeholder {
  text-align: center;
}

.native-input.sc-ion-input-ios {
  padding: 10px;
}
.backstoryFeed {
  padding: 5px;
  text-align: justify;
}

.join-custom-class {
  --width: 60%;
  --height: 50%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
}

.home {
  --background: url("/assets/TestApp1200x720.png") center/100% auto no-repeat;
}

.app-page {
  background: #005494;

}

.completed-by-modal {

  --width: 90%;
  --height: 70%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;

}

.stop-modal {
  --width: 90%;
  --height: 90%;
  --background: #005494;
  overflow-y: scroll;
}

.appBrandText {
  color: #ffffff;
}

ion-select {
  /* Applies to the value and placeholder color */
  --background: #005494;
  /* Set a different placeholder color */
  --placeholder-color: #ffffff;

  /* Set full opacity on the placeholder */
  --placeholder-opacity: 1;
}


ion-select::part(icon) {
          color: #ffffff;
          opacity: 1;
}

.completed-by-modal {

  --width: 90%;
  --height: 70%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;

}

.stop-modal {
  --width: 90%;
  --height: 70%;
  --background: #005494;
  overflow-y: scroll;
}

.appBrandText {
  color: #ffffff;
  opacity: 1;
}

.messageBox {
  --background: rgba(180,180,180,0.3);
  /*--background: rgba(0,84,148,0.3);*/
  --overflow: hidden;
}

.messages {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}

.message {
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.yours {
  align-items: flex-start;
}

.yours .message {
  margin-right: 25%;
  background-color: #eee;
  position: relative;
}

.yours .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: -7px;
  height: 20px;
  width: 20px;
  background: #eee;
  border-bottom-right-radius: 15px;
}
.yours .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: -10px;
  width: 10px;
  height: 20px;
  background: white;
  border-bottom-right-radius: 10px;
}

.mine {
  align-items: flex-end;
}

.mine .message {
  color: white;
  margin-left: 25%;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  position: relative;
}

.mine .message.last:before {
  content: "";
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: -8px;
  height: 20px;
  width: 20px;
  background: linear-gradient(to bottom, #00D0EA 0%, #0085D1 100%);
  background-attachment: fixed;
  border-bottom-left-radius: 15px;
}

.mine .message.last:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;

  width: 10px;
  height: 20px;
  background: white;
  border-bottom-left-radius: 10px;
}

.castChatText {
  right: -10px;

}

.chatText {
  white-space: pre-line;
  fontSize: 14x;
}

.in-character {
  color:#005494;
}

.inputBackground {
  border-radius: 10px !important;
  /* padding-left: 30px !important; */
  font-size: 0.9em;
  margin-bottom: 15px;
  border: 1px solid #005494;
  /* border-bottom: 0px !important; */
  box-shadow: grey !important;
}

.pwdInput {
  border-radius: 10px !important;
  padding-left:20px !important;
  font-size: 0.9em;
  margin-bottom: 15px;
  border: 1px solid #005494;
  /* border-bottom: 0px !important; */
  box-shadow: grey !important;
}

.brandMessage {
  background: #00ff00 url("/assets/icon/icons-192x192.png");
  –width:250px;
  –min-height: 250px;
}


.chat-welcome-class {
  --width: 70%;
  --height: 50%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;

}

.commentAuthor {
  font-size: 18px;
  font-weight: bolder;
}

.subscribe-modal {
  --width: 90%;
  --height: 50%;
  --background: rgba(0, 0, 0, 0.75);
  overflow-y: scroll;

}

.my-assets-class {
  --width: 70%;
  --height: 85%;
  --background: rgba(0, 0, 0, 0.75);
}

.desktopBanner {
  --width: 100%;
  --height: 100%;
  --background: rgba(0, 0, 0, 0.75);
}

.highContrast {
  color: white;
  /*mix-blend-mode: difference;*/
}

.duplicateModal {
  padding-top: 60%;
  --width: 80%;
  --height: 25%;
  --background: rgba(0, 84,148);
  overflow-y: scroll;

}

.desktop-overlay {
  position:absolute;
  top:0;
  left:0;
  z-index:1;
  width: 50%;
  height: 60%;
}

.videoPlayBtn {
  position: absolute;
  bottom: 2.5%;
  right: 6px;
  background-color: #005494;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.item.sc-ion-label-ios-h, .item .sc-ion-label-ios-h
{
  overflow:visible !important;
}